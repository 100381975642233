var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WaBtn'),_c('MainBanner'),_vm._m(0),_c('div',{staticClass:"pt-10"},[_c('div',{staticClass:"container lg:relative"},[_c('div',{},[_vm._m(1),_c('div',{staticClass:"mt-7"},[_c('div',{staticClass:"md:flex md:flex-wrap"},_vm._l((_vm.choose_us),function(item,i){return _c('div',{key:i,staticClass:"w-4/5 pb-5 mx-auto md:w-1/2 lg:w-1/4 md:px-2"},[_c('div',{staticClass:"p-6 transition duration-200 ease-linear transform bg-white border-2 rounded-lg border-gold-1 hover:scale-105 hover:shadow-lg"},[_c('img',{staticClass:"w-24 mx-auto",attrs:{"src":'/images/' + item.image + '.png',"alt":item.alt}}),_c('div',{staticClass:"pt-3 text-center"},[_c('h1',{staticClass:"text-xl font-semibold leading-tight lg:h-10 text-black-1"},[_vm._v(" "+_vm._s(item.alt)+" ")]),_c('p',{staticClass:"mt-4 text-sm leading-tight text-gray-600 md:h-20 lg:h-36"},[_vm._v(" "+_vm._s(item.p)+" ")])])])])}),0)])]),_c('div',{staticClass:"hidden bg-white lg:block h-96"}),_vm._m(2)])]),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(3),_c('div',[_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplaySpeed":2500,"autoplayTimeout":6000,"responsive":{
            0: { items: 2, nav: false, dots: true },
            767: { items: 3, nav: false, dots: true },
            1000: { items: 5, nav: false, dots: true },
          }}},_vm._l((_vm.brands),function(image){return _c('div',{key:_vm.i,staticClass:"px-1"},[_c('img',{staticClass:"w-auto",attrs:{"src":'/images/' + image + '.jpg',"alt":""}})])}),0)],1)])]),_c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/bg-service.jpg')"}},[_c('div',{staticClass:"container"},[_vm._m(4),_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"md:flex md:flex-wrap xl:max-w-4xl xl:mx-auto"},_vm._l((_vm.services),function(item,i){return _c('div',{key:i,staticClass:"w-2/3 pb-5 mx-auto text-white transition duration-200 ease-linear transform md:w-1/2 lg:w-1/3 md:px-3 hover:scale-105 hover:text-gold-2"},[_c('img',{attrs:{"src":'/images/' + item.image + '.jpg',"alt":item.alt}}),_c('div',{staticClass:"pt-4 text-center"},[_c('p',{staticClass:"text-lg font-bold leading-tight"},[_vm._v(_vm._s(item.alt))])])])}),0)])])]),_c('div',{staticClass:"pt-10"},[_c('div',{staticClass:"container lg:flex lg:items-center"},[_c('div',{staticClass:"pb-10 lg:pb-0 lg:w-1/2"},[_vm._m(5),_c('div',[_c('carousel',{attrs:{"autoplay":true,"loop":true,"autoplaySpeed":2500,"autoplayTimeout":6000,"responsive":{
              0: { items: 1, nav: false, dots: true },
            }}},_vm._l((_vm.testimonials),function(item,i){return _c('div',{key:i,staticClass:"px-1 md:px-3"},[_c('div',{staticClass:"text-center text-gray-600"},[_c('div',{staticClass:"pt-3 pb-20"},[_c('div',{staticClass:"pb-4 text-yellow-400"},[_c('i',{staticClass:"fa-sharp fa-solid fa-star"}),_c('i',{staticClass:"fa-sharp fa-solid fa-star"}),_c('i',{staticClass:"fa-sharp fa-solid fa-star"}),_c('i',{staticClass:"fa-sharp fa-solid fa-star"}),_c('i',{staticClass:"fa-sharp fa-solid fa-star"})]),_c('p',{staticClass:"h-16 text-sm"},[_vm._v(" "+_vm._s(item.p)+" ")])]),_c('div',{staticClass:"pt-4"},[_c('p',{staticClass:"text-base font-semibold"},[_vm._v(" "+_vm._s(item.client)+" ")])])])])}),0)],1)]),_vm._m(6)])]),_c('section',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/bg-enquiry.jpg')"},attrs:{"id":"enquiry"}},[_c('div',{staticClass:"container lg:flex lg:items-center"},[_c('div',{staticClass:"md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2"},[_c('div',{staticClass:"p-6 rounded-md bg-gold-1"},[_vm._m(7),_c('EnquiryForm',{staticClass:"pt-4"})],1)]),_vm._m(8)])]),_vm._m(9)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10 bg-black"},[_c('div',{staticClass:"container lg:flex lg:items-center"},[_c('div',{staticClass:"lg:w-1/2"},[_c('img',{staticClass:"mx-auto md:w-1/2 lg:w-auto",attrs:{"src":"/images/about-us.png","alt":""}})]),_c('div',{staticClass:"mt-5 text-white lg:w-1/2 lg:mt-0"},[_c('div',{staticClass:"pl-3 border-l-2 border-gold-2"},[_c('p',{staticClass:"text-xl font-semibold text-gold-1"},[_vm._v("Welcome to")]),_c('h1',{staticClass:"text-2xl font-bold md:text-3xl"},[_vm._v(" D & S Autoworks Sdn Bhd ")])]),_c('div',{staticClass:"pt-4 text-sm text-gray-200"},[_c('p',{staticClass:"leading-tight"},[_vm._v(" At D&S Autoworks, we are a leading car workshop specializing in exceptional tyre services and auto parts repairs. With years of experience and a team of skilled technicians, we are dedicated to providing reliable and efficient solutions for your vehicle. Our workshop is equipped with state-of-the-art equipment, allowing us to deliver top-notch services such as tyre rotation, wheel alignment, and tyre replacement. Additionally, our expert mechanics excel in auto parts repairs, including brake system maintenance, suspension repairs, and engine diagnostics. We take pride in our commitment to customer satisfaction, ensuring that your car receives the highest level of care and attention. Trust us for all your car maintenance needs and experience the difference at D&S Autoworks. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center lg:max-w-3xl lg:mx-auto"},[_c('h1',{staticClass:"text-3xl font-bold text-black-1"},[_vm._v(" Why "),_c('span',{staticClass:"text-gold-1"},[_vm._v("Choose Us")])]),_c('div',{staticClass:"bg-gold-1 w-12 h-0.5 mx-auto my-3"}),_c('p',{staticClass:"pt-4 text-sm text-gray-600"},[_vm._v(" With over 20 years of experience in this field, we provide top-quality service, efficient solutions, and a personalized experience that exceeds expectations. Our vision is to ensure safety, performance, and peace of mind for every customer. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 lg:absolute lg:bottom-0 lg:w-2/3 xl:w-3/5 lg:right-0 lg:mt-0 lg:mx-0 md:mx-auto"},[_c('img',{attrs:{"src":"/images/bg-choose.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-3xl font-bold text-black-1"},[_vm._v(" Authorized "),_c('span',{staticClass:"text-gold-1"},[_vm._v("Distributor")])]),_c('div',{staticClass:"bg-gold-1 w-12 h-0.5 mx-auto my-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center lg:max-w-3xl lg:mx-auto"},[_c('h1',{staticClass:"text-3xl font-bold text-white"},[_vm._v(" Our "),_c('span',{staticClass:"text-gold-1"},[_vm._v("Services")])]),_c('div',{staticClass:"bg-gold-1 w-12 h-0.5 mx-auto my-3"}),_c('p',{staticClass:"pt-4 text-sm text-gray-200"},[_vm._v(" We are the one-top solutions workshop which offering a comprehensive range of solutions under one roof. We are your go-to destination for all your automotive needs!’ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-3xl font-bold text-black-1"},[_vm._v(" What Our "),_c('br',{staticClass:"lg:hidden"}),_c('span',{staticClass:"text-gold-1"},[_vm._v("Customers Say")])]),_c('div',{staticClass:"bg-gold-1 w-12 h-0.5 mx-auto my-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-2/3 lg:w-1/2 md:mx-auto"},[_c('img',{staticClass:"lg:w-4/5 lg:mx-auto",attrs:{"src":"/images/bg-testi.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-3xl font-semibold text-black-1"},[_vm._v("Send an Enquiry")]),_c('p',{staticClass:"pt-3 text-sm leading-tight text-white"},[_vm._v(" Fill out the form below and we'll get back to you as soon as possible ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-10 lg:w-1/2 lg:pr-4"},[_c('img',{staticClass:"mx-auto w-44 lg:w-60",attrs:{"src":"/images/main-logo.png","alt":""}}),_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-2xl font-bold lg:text-3xl text-gold-1"},[_vm._v(" D&S Autoworks Sdn Bhd ")]),_c('p',{staticClass:"pt-4 leading-tight text-white"},[_vm._v(" No1, Jalan 9/23e, Taman Danau Kota, Jalan Genting Kelang, Batu 41/2, 53300 Kuala Lumpur ")]),_c('div',{staticClass:"pt-4"},[_c('p',{staticClass:"text-lg font-semibold text-white transition duration-200 ease-linear transform hover:text-gold-1"},[_c('a',{attrs:{"href":"tel:+60103333233"}},[_vm._v("Call Us : 010-333 3233")])])]),_c('div',{staticClass:"pt-4 text-white"},[_c('p',[_vm._v("Operating Hours")]),_c('p',[_vm._v("Monday to Saturday: 9:00 AM – 7:00 PM")]),_c('p',[_vm._v("Sunday: Closed")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-3 bg-white"},[_c('div',{staticClass:"container mx-auto"},[_c('p',{staticClass:"text-xs text-center text-gray-900 lg:text-sm"},[_vm._v(" Website maintained by Activa Media. All Rights Reserved. ")])])])
}]

export { render, staticRenderFns }