<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- about us -->
    <div class="py-10 bg-black">
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2">
          <img
            src="/images/about-us.png"
            alt=""
            class="mx-auto md:w-1/2 lg:w-auto"
          />
        </div>
        <div class="mt-5 text-white lg:w-1/2 lg:mt-0">
          <div class="pl-3 border-l-2 border-gold-2">
            <p class="text-xl font-semibold text-gold-1">Welcome to</p>
            <h1 class="text-2xl font-bold md:text-3xl">
              D & S Autoworks Sdn Bhd
            </h1>
          </div>
          <div class="pt-4 text-sm text-gray-200">
            <p class="leading-tight">
              At D&S Autoworks, we are a leading car workshop specializing in
              exceptional tyre services and auto parts repairs. With years of
              experience and a team of skilled technicians, we are dedicated to
              providing reliable and efficient solutions for your vehicle. Our
              workshop is equipped with state-of-the-art equipment, allowing us
              to deliver top-notch services such as tyre rotation, wheel
              alignment, and tyre replacement. Additionally, our expert
              mechanics excel in auto parts repairs, including brake system
              maintenance, suspension repairs, and engine diagnostics. We take
              pride in our commitment to customer satisfaction, ensuring that
              your car receives the highest level of care and attention. Trust
              us for all your car maintenance needs and experience the
              difference at D&S Autoworks.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="pt-10">
      <div class="container lg:relative">
        <div class="">
          <div class="text-center lg:max-w-3xl lg:mx-auto">
            <h1 class="text-3xl font-bold text-black-1">
              Why <span class="text-gold-1">Choose Us</span>
            </h1>
            <div class="bg-gold-1 w-12 h-0.5 mx-auto my-3"></div>
            <p class="pt-4 text-sm text-gray-600">
              With over 20 years of experience in this field, we provide
              top-quality service, efficient solutions, and a personalized
              experience that exceeds expectations. Our vision is to ensure
              safety, performance, and peace of mind for every customer.
            </p>
          </div>
          <div class="mt-7">
            <div class="md:flex md:flex-wrap">
              <div
                v-for="(item, i) in choose_us"
                :key="i"
                class="w-4/5 pb-5 mx-auto md:w-1/2 lg:w-1/4 md:px-2"
              >
                <div
                  class="p-6 transition duration-200 ease-linear transform bg-white border-2 rounded-lg border-gold-1 hover:scale-105 hover:shadow-lg"
                >
                  <img
                    :src="'/images/' + item.image + '.png'"
                    :alt="item.alt"
                    class="w-24 mx-auto"
                  />
                  <div class="pt-3 text-center">
                    <h1
                      class="text-xl font-semibold leading-tight lg:h-10 text-black-1"
                    >
                      {{ item.alt }}
                    </h1>
                    <p
                      class="mt-4 text-sm leading-tight text-gray-600 md:h-20 lg:h-36"
                    >
                      {{ item.p }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden bg-white lg:block h-96"></div>
        <div
          class="mt-4 lg:absolute lg:bottom-0 lg:w-2/3 xl:w-3/5 lg:right-0 lg:mt-0 lg:mx-0 md:mx-auto"
        >
          <img src="/images/bg-choose.png" alt="" />
        </div>
      </div>
    </div>

    <!-- dealer -->
    <div class="py-10">
      <div class="container">
        <div class="text-center">
          <h1 class="text-3xl font-bold text-black-1">
            Authorized <span class="text-gold-1">Distributor</span>
          </h1>
          <div class="bg-gold-1 w-12 h-0.5 mx-auto my-3"></div>
        </div>

        <div>
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 2, nav: false, dots: true },
              767: { items: 3, nav: false, dots: true },
              1000: { items: 5, nav: false, dots: true },
            }"
          >
            <div v-for="image in brands" :key="i" class="px-1">
              <img :src="'/images/' + image + '.jpg'" alt="" class="w-auto" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- service -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-service.jpg')"
    >
      <div class="container">
        <div class="text-center lg:max-w-3xl lg:mx-auto">
          <h1 class="text-3xl font-bold text-white">
            Our <span class="text-gold-1">Services</span>
          </h1>
          <div class="bg-gold-1 w-12 h-0.5 mx-auto my-3"></div>
          <p class="pt-4 text-sm text-gray-200">
            We are the one-top solutions workshop which offering a comprehensive
            range of solutions under one roof. We are your go-to destination for
            all your automotive needs!’
          </p>
        </div>

        <div class="mt-6">
          <div class="md:flex md:flex-wrap xl:max-w-4xl xl:mx-auto">
            <div
              v-for="(item, i) in services"
              :key="i"
              class="w-2/3 pb-5 mx-auto text-white transition duration-200 ease-linear transform md:w-1/2 lg:w-1/3 md:px-3 hover:scale-105 hover:text-gold-2"
            >
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class=""
              />
              <div class="pt-4 text-center">
                <p class="text-lg font-bold leading-tight">{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="pt-10">
      <div class="container lg:flex lg:items-center">
        <div class="pb-10 lg:pb-0 lg:w-1/2">
          <div class="text-center">
            <h1 class="text-3xl font-bold text-black-1">
              What Our <br class="lg:hidden" />
              <span class="text-gold-1">Customers Say</span>
            </h1>
            <div class="bg-gold-1 w-12 h-0.5 mx-auto my-3"></div>
          </div>
          <div>
            <carousel
              :autoplay="true"
              :loop="true"
              :autoplaySpeed="2500"
              :autoplayTimeout="6000"
              :responsive="{
                0: { items: 1, nav: false, dots: true },
              }"
            >
              <div
                v-for="(item, i) in testimonials"
                :key="i"
                class="px-1 md:px-3"
              >
                <div class="text-center text-gray-600">
                  <div class="pt-3 pb-20">
                    <div class="pb-4 text-yellow-400">
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                    </div>
                    <p class="h-16 text-sm">
                      {{ item.p }}
                    </p>
                  </div>
                  <div class="pt-4">
                    <p class="text-base font-semibold">
                      {{ item.client }}
                    </p>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>

        <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
          <img src="/images/bg-testi.png" alt="" class="lg:w-4/5 lg:mx-auto" />
        </div>
      </div>
    </div>

    <!-- footer -->
    <section
      id="enquiry"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-gold-1">
            <div class="text-center">
              <p class="text-3xl font-semibold text-black-1">Send an Enquiry</p>
              <p class="pt-3 text-sm leading-tight text-white">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-10 lg:w-1/2 lg:pr-4">
          <img
            src="/images/main-logo.png"
            alt=""
            class="mx-auto w-44 lg:w-60"
          />
          <div class="text-center">
            <h1 class="text-2xl font-bold lg:text-3xl text-gold-1">
              D&S Autoworks Sdn Bhd
            </h1>
            <p class="pt-4 leading-tight text-white">
              No1, Jalan 9/23e, Taman Danau Kota, Jalan Genting Kelang, Batu
              41/2, 53300 Kuala Lumpur
            </p>
            <div class="pt-4">
              <p
                class="text-lg font-semibold text-white transition duration-200 ease-linear transform hover:text-gold-1"
              >
                <a href="tel:+60103333233">Call Us : 010-333 3233</a>
              </p>
            </div>
            <div class="pt-4 text-white">
              <p>Operating Hours</p>
              <p>Monday to Saturday: 9:00 AM – 7:00 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-3 bg-white">
      <div class="container mx-auto">
        <p class="text-xs text-center text-gray-900 lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue"

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
    WaBtn
  },
  data() {
    return {
      choose_us: [
        {
          image: "choose-1",
          alt: "Experienced Mechanics",
          p: "We have a team of highly skilled technicians who have the knowledge and expertise to handle all your car maintenance needs. ",
        },
        {
          image: "choose-2",
          alt: "One-stop Service",
          p: "D&S Autoworks is a leading one-stop service car workshop providing exceptional tyre services and auto parts repairs. We offering a comprehensive range of solutions under one roof.",
        },
        {
          image: "choose-3",
          alt: "Quality Parts",
          p: "At D&S Autoworks, we believe in using only genuine and high-quality parts for all our repairs and services.",
        },
        {
          image: "choose-4",
          alt: "Affordable Price",
          p: "You can get fair and transparent pricing at D&S Autoworks, as we aim to deliver costeffective solutions that fit your budget.",
        },
      ],
      brands: [
        "brand-1",
        "brand-2",
        "brand-3",
        "brand-4",
        "brand-5",
        "brand-6",
      ],
      services: [
        { image: "service-1", alt: "Tyre Services" },
        { image: "service-2", alt: "Rim Replacement" },
        { image: "service-3", alt: "Brake System Maintenance" },
        { image: "service-4", alt: "Steering & Suspension Repairs" },
        { image: "service-5", alt: "Engine Diagnostics" },
        { image: "service-6", alt: "Electrical System Repairs" },
      ],
      testimonials: [
        {
          p: "Fast service, good and well trained techinican...averaged as market value price..the owner also polite and easy to discuss,tq 4 the kfc voucher.. mantap beb..",
          client: "Hariz Haidzir",
        },
        {
          p: "I sent my car for the first time to this workshop for engine and CVT transmission. They immediately do their job, and this workshop gives a reasonable price for their service. Definitely will came back to this workshop in the future for engine and tyre services",
          client: "Elara Fay",
        },
        {
          p: "Price can be negotiable with owner. Recommended. Rpf1 sportrims(copy), tyre new 2 nos, balancing, alignment, spacer, metal valve, heptagon nut, trade in rims 15, total cost RM900.00. got voucher also. Friendly mechanic",
          client: "Muhammad Fawzy",
        },
      ],
    };
  },
};
</script>
