<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover xl:bg-bottom"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <header
        v-if="!hideEnquiry"
        class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear lg:py-5"
        :class="
          fixtop ? ' bg-black-1 shadow-md z-10' : ' bg-gradient-to-b from-black'
        "
      >
        <div class="container">
          <div class="flex items-center justify-between text-white">
            <h1 class="text-2xl font-bold lg:text-3xl">
              D&S <span class="text-gold-1">Autoworks</span>
            </h1>
            <div>
              <a href="tel:+60103333233">
                <img src="/images/phone.png" alt="" class="w-12 mx-auto lg:hidden" />
                <div class="hidden lg:text-right lg:block">
                  <p class="text-lg font-semibold">Call Us:</p>
                  <p class="p-1 text-2xl font-bold text-center text-gray-700 transition duration-200 ease-linear bg-white rounded-lg hover:text-white hover:bg-red-600 w-44">010-333 3233</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </header>

      <!-- banner tagline -->
      <div class="py-48 md:py-52 lg:py-56 xl:py-64">
        <div class="container">
          <div class="md:w-2/3">
            <div class="text-white">
              <div class="pl-3 border-l-4 border-gold-2">
                <h1 class="text-4xl font-bold leading-tight md:text-5xl lg:text-6xl">
                  Setapak <span class="text-gold-2">#1</span> <br />
                  Tyre & Car Workshop
                </h1>
                <p class="pt-2 text-2xl font-semibold">Specialise in Car Tyre and Repair Services</p>
              </div>
              <p></p>

              <div v-if="!hideEnquiry" class="mt-8">
                <p
                  class="w-40 py-1 text-center transition duration-200 ease-linear border-2 rounded-lg border-gold-2 hover:text-gray-800 hover:bg-gold-2"
                >
                  <a href="tel:+60103333233"> Call Us Now </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
